<template>
    <Button @export="exportData" action="export" type="fill" :name="name" :noMargin="true"/>
</template>
<script>
import Button from '@/components/forms/Button.vue'
import barramento from '@/barramento'
export default {
    props:['name','type','query', 'selected'],
    components:{Button},
    data() {
        return {
            endpoint: '/search',
        }
    },
    methods: {
        exportData() {    
            let s = '&export='+this.type
            if(this.selected.length > 0){
                s = '?'+ s + '&selectedIds=' + this.selected
            }else{
                s = this.query + s; 
            }
            barramento.$emit('modal_export', this.endpoint + s)
            
        },
    }
}
</script>
